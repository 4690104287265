import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
// import  "../assets/css/bootstrap.min.css" 
// import "../assets/css/icons.min.css" 
// import  "../assets/css/app.min.css"
// import  "../assets/css/style.css" 


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundImage: `url(/assets/images/auth3-light.webp)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');


  return (
    <>
      <Helmet>
        <title> Login | ASSR </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {/* {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )} */}

        <Container maxWidth="sm">
          <StyledContent>
            {/* <Typography variant="h4" gutterBottom>
              Sign in to Carib Bargains
            </Typography> */}

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Get started</Link>
            </Typography> */}

            {/* <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack> */}

            {/* <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> */}

            <LoginForm />






            {/* <div className="container">
        <div className="row justify-content-center align-items-center">
          <div>
            <div className="card">
              <div className="card-body">
                <div className="text-center mt-4">
                  <div className="mb-0">
                    <Link to="/home" className="">
                      <img src="/assets/images/logo.svg" alt="" height="62" className="auth-logo logo-dark mx-auto" />
                    </Link>
                  </div>
                </div>
                <div className="p-3 mt-0">
                  <h4 className="font-size-18 text-muted mt-2 text-center">Welcome Back !</h4>
                  <p className="text-muted text-center mb-4">Sign in to continue to ASSR</p>
                  <form className="form-horizontal" action="index.html">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="username">Enter Username</label>
                      <input type="text" className="form-control" id="username" placeholder="Enter username" />
                    </div>

                     <div className="mb-3">
                      <label className="form-label" htmlFor="userpassword">Password</label>
                      <input type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                    </div>

                   <div className="mb-2 row mt-4">
                      <div className="col-sm-6">
                        <div className="form-checkbox">
                          <input type="checkbox" className="form-check-input me-1" id="customControlInline" />
                          <label className="form-label form-check-label" htmlFor="customControlInline">Remember me</label>
                        </div>
                      </div>

                      <div className="col-sm-6 text-end">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock"/> Forgot your password?
                        </Link>
                      </div>
                    </div>

                    <div className="row mb-3 mt-4">
                      <div className="col-12 text-center">
                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Login</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  */}







          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
