import http from '../http-common';
import authHeader from './auth-header';

const getAll = () => http.get('/admin/users', { headers: authHeader() });

const getPublicAll = () => http.get('/users');

const get = (id) => http.get(`/user/${id}`);

const getUserList = () => http.get('/user');

const getuselist = (params) =>
  http.get(`/UserDetailList`, {
    params,
    headers: authHeader(),
  });

function create(data) {
  console.log(data);
  return http.post('/users', data);
}

// const getbyid = (id) => http.get(`/UserDetailList/${id}`);
const getbyid = (UserGuid) =>
  http.get(`/UserDetailListById`, {
    params: {
      UserGuid,
    },
  });

const remove = (userGuid) =>
  http.delete(`/DeleteUser?userGuid=${userGuid}`, null, {
    params: { userGuid },
  });

// https://assr.digitalnoticeboard.biz/api/DeleteUser?userGuid=8643bd33-262f-4892-acd9-09959683d08f
// https://assr.digitalnoticeboard.biz/api/UpdateUserStatus?userguid=938403cb-f7c6-43ec-9564-4adf3690880d&Status=true

const updateuserStatus = (userId, status) => http.put(`/UpdateUserStatus?Status=${status}&userguid=${userId}`);

// const update = (id, data) => http.post(`/users/edit/${id}`, data);

// const remove = (id) => http.post(`/users/delete/${id}`);
// const remove = (userGuid) => http.post(`/DeleteUser?userGuid=${userGuid}`);

// const findNearestLocation = (address, latitude, longitude) => http.get(`/users/search?address=${address}&latitude=${latitude}&longitude=${longitude}`);

// const upload = (file, email, onUploadProgress) => {
//   let formData = new FormData();

//   formData.append("file", file);
//   formData.append("email", email);

//   return http.post("/users/uploadFile", formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//     onUploadProgress,npm
//   });
// };

// const getPaginationAll = (params) => http.get("/user/all", { params });

const getPaginationWithAddrAndCompany = (params) => http.get('/user/all', { params });

const getGlobalSearch = (params) => http.get('/users/paging', { params });
const getProduct = () => http.get('/product/all');

// ASSR APIS
const changePassword = (data) => http.post('/ChangePassword/ChangePassword', data);

const UserServices = {
  getAll,

  getbyid,
  get,
  create,
  remove,
  getPaginationWithAddrAndCompany,
  getPublicAll,
  getGlobalSearch,
  getProduct,
  // ASSR
  changePassword,
  getuselist,
  updateuserStatus,
};

export default UserServices;
