import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import React, { useEffect, useState } from 'react';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

// @mui
import {
  Card,
  Tooltip,
  Stack,
  Button,
  Container,
  Typography,
  Box,
  styled,
  Pagination,
  Avatar,
  Switch,
} from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import FormControlLabel from '@mui/material/FormControlLabel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import './customStyles.css';
import { DataGrid, GridPagination, GridToolbar, GridFooterContainer } from '@mui/x-data-grid';
import UserServices from '../services/UserServices';

// components
import Iconify from '../components/iconify';

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function UserPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showproduct, setShowproduct] = useState(false);
  const handleCloseproduct = () => setShowproduct(false);
  const handleShowproduct = () => setShowproduct(true);

  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [refreshList, setRefreshList] = useState(true);

  const formatDate = (isoString) => {
    return moment(isoString).format('MMMM D, YYYY h:mm:ss A');
  };

  const userGuid = '24ae86cc-b373-405a-94ee-d7a2775da405';

  const columns = [
    {
      field: 'email',
      headerName: 'User ID',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'photoUrl',
      headerName: 'Photo',
      width: 120,
      sortable: false,
      renderCell: (params) => <Avatar src={params.value} />,
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'userName',
      headerName: 'User Name',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },

    // {
    //   field: 'email',
    //   headerName: 'Email',
    //   headerClassName: 'super-app-theme--header',

    //   width: 250,
    //   headerAlign: 'Start',
    //   align: 'Start',
    // },

    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'Start',
      align: 'Start',
    },

    // {
    //   field: 'isActive',
    //   headerName: 'Status',
    //   width: 120,
    //   headerClassName: 'super-app-theme--header',
    //   headerAlign: 'start',

    //   renderCell: (params) => {
    //     const toggleStatus = async () => {
    //       setLoading(true);
    //       const newStatus = !params.value; // Toggle the status

    //       const url = `https://assr.digitalnoticeboard.biz/api/UpdateUserStatus?userguid=${userGuid}&status=${newStatus}`;

    //       try {
    //         const response = await fetch(url, {
    //           method: 'POST',
    //         });

    //         if (response.ok) {
    //           const updatedData = data.map((row) => (row.id === params.row.id ? { ...row, status: newStatus } : row));
    //           setData(updatedData);
    //           toast.success('Success : Updated Successfully', {
    //             position: toast.POSITION.TOP_RIGHT,
    //           });
    //         } else {
    //           toast.error('Please fill in all fields', {
    //             position: toast.POSITION.TOP_RIGHT,
    //           });
    //           console.error('Status update failed');
    //         }
    //       } catch (error) {
    //         toast.error('Something went wrong!', {
    //           position: toast.POSITION.TOP_RIGHT,
    //         });
    //         console.error('Error updating status:', error);
    //       }

    //       setLoading(false);
    //     };

    //     return (
    //       <FormControlLabel control={<IOSSwitch checked={params.value} onChange={toggleStatus} sx={{ m: 1 }} />} />
    //     );
    //   },
    // },
    {
      field: 'lockoutEnabled',
      headerName: 'Status',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderCell: (params) => {
        const toggleStatus = async () => {
          setLoading(true);
          const newStatus = !params.value; // Toggle the status

          try {
            // Use the common API method to update status
            const response = await UserServices.updateuserStatus(params.row.id, newStatus);

            if (response.status === 200) {
              // Update your local data or state to reflect the new status
              const updatedData = data.map((row) =>
                row.id === params.row.id ? { ...row, lockoutEnabled: newStatus } : row
              );

              // console.log('kp.row.email: ', updatedData, params.value, newStatus);
              setData(updatedData);

              // Update the checkbox value
              params.value = newStatus;

              toast.success('Success: Updated Successfully', {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error('Error: Status Not Changed', {
                position: toast.POSITION.TOP_RIGHT,
              });
              console.error('Status update failed');
            }
          } catch (error) {
            toast.error('Something went wrong!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.error('Error updating status:', error);
          }

          setLoading(false);
        };
        const toogleCheck = data.filter((row) => row.id === params.row.id);
        console.log('toogleCheck : ', toogleCheck);
        return (
          <FormControlLabel
            control={<IOSSwitch checked={toogleCheck[0].lockoutEnabled} onChange={toggleStatus} sx={{ m: 1 }} />}
          />
        );
      },
    },

    {
      field: 'Action',
      width: 190,
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        const onClickView = async (params) => {
          try {
            const response = await UserServices.getbyid(params.id); // Call the getbyid method from UserServices

            if (response.status === 200) {
              const responseData = response.data;
              console.log('Fetched user data:', responseData); // Debug: Check if data is fetched correctly

              if (Array.isArray(responseData) && responseData.length > 0) {
                setCurrentUser(responseData[0]); // Set the first item of the array
              } else {
                console.error('Unexpected response format or empty array');
              }
            } else {
              console.error('Error fetching user details:', response.status);
            }
          } catch (error) {
            console.error('Fetch error:', error);
          }
        };

        // -----remove--user-list-------

        // const handleDelete = async (id) => {
        //   try {
        //     await UserServices.remove(id); // Call your delete API
        //     // Update state to remove the deleted item
        //     const temp = data.filter((row) => row.id !== id);
        //     // setData((prevData) => prevData.filter((item) => item.id !== id));
        //     setData(temp);
        //     console.log('Item deleted successfully.');
        //   } catch (error) {
        //     console.error('Failed to delete:', error);
        //   }
        // };
        const handleDelete = async (id) => {
          Swal.fire({
            title: 'Are you sure you want to delete this user?',

            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'confirm-button-success', // Custom class for the confirm button
              cancelButton: 'confirm-button-red', // Custom class for the confirm button
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await UserServices.remove(id); // Call your delete API
                const temp = data.filter((row) => row.id !== id);
                setData(temp);
                console.log('Item deleted successfully.');
                Swal.fire('Deleted!', 'The item has been deleted.', 'success');
              } catch (error) {
                console.error('Failed to delete:', error);
                Swal.fire('Error!', 'Failed to delete the item.', 'error');
              }
            }
          });
        };

        return (
          <>
            <Tooltip title="User Subscriptions" arrow>
              <Link to="/dashboard/usersubscription">
                <Button
                  variant="outlined"
                  startIcon={<SubscriptionsIcon />}
                  style={{ border: 'none', borderRadius: 0, minWidth: '40px', padding: '0px' }}
                />
              </Link>
            </Tooltip>
            <Tooltip title="Transactions" arrow>
              <Link to="/dashboard/transaction">
                <Button
                  variant="outlined"
                  startIcon={<PaymentIcon />}
                  color="success"
                  style={{ border: 'none', borderRadius: 0, minWidth: '40px', padding: '0px' }}
                />
              </Link>
            </Tooltip>
            <Tooltip title="View" arrow>
              <Button
                onClick={() => {
                  onClickView(params);
                  handleShowproduct();
                }}
                variant="outlined"
                startIcon={<VisibilityIcon />}
                color="warning"
                style={{ border: 'none', borderRadius: 0, minWidth: '40px', padding: '0px' }}
              />
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <Button
                onClick={() => handleDelete(params.row.id)}
                variant="outlined"
                startIcon={<DeleteIcon />}
                color="error"
                style={{ border: 'none', borderRadius: 0, minWidth: '40px', padding: '0px' }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const initialUserService = {
    id: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    photoUrl: '',
    createdOn: '',
    userName: '',
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortColumn, setSortColumn] = useState('Id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [totalItems, setTotalItems] = useState('');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageSizes = [25, 50, 100];

  const getRequestParams = (page, pageSize) => {
    const paramss = {};
    if (page) {
      paramss.pageNumber = page - 1;
    }
    if (pageSize) {
      paramss.pageSize = pageSize;
    }

    if (sortColumn) {
      paramss.sortColumn = sortColumn;
    }
    if (sortOrder) {
      paramss.sortOrder = sortOrder;
    }
    return paramss;
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event);
    setPage(1);
  };

  const retrieveTutorials = () => {
    // setLoading(true);
    const params = getRequestParams(page, pageSize);

    UserServices.getuselist(params)
      .then((response) => {
        const { totalRecords, pageSize, currentPage, totalPages, data } = response.data.paginationMetadata;
        if (data === 0 || data === undefined) {
          setData(0);
          console.log('talib data', data);
        } else {
          setData(data);
        }
        setTotalItems(totalItems);
        setCount(totalPages);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(retrieveTutorials, [page, pageSize, refreshList]);

  function CustomPagination() {
    return (
      <>
        <Pagination
          className="my-3"
          color="secondary"
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <Box>
          <div className="float-right">
            Showing the first {pageSize * (page - 1) + 1} -{' '}
            {totalItems > pageSize * (page - 1) + pageSize ? pageSize * (page - 1) + pageSize : totalItems} results of{' '}
            {totalItems} rows
          </div>
          <Typography>
            Page: {page} of {count}
          </Typography>
        </Box>
      </>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer>
        <CustomPagination />
        <GridPagination />
      </GridFooterContainer>
    );
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  return (
    <>
      <Helmet>
        <title> ASSR </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            User Management
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <Box sx={{ height: 600, width: '100%', padding: '20px' }}>
            <DataGrid
              // sx={{
              //   boxShadow: 2,
              //   border: 2,
              //   borderColor: "primary.light",
              //   "& .MuiDataGrid-cell:hover": {
              //     color: "primary.main",
              //   },
              // }}
              rows={data}
              disableColumnMenu
              disableSelectionOnClick
              columns={columns}
              onSelectionModelChange={setSelectionModel}
              selectionModel={selectionModel}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={pageSizes}
              // checkboxSelection
              preProcessEditCellProps
              localeText={{
                footerRowSelected: CustomPagination,
              }}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: CustomNoRowsOverlay,
                Footer: CustomFooter,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              loading={loading}
              {...data}
            />
          </Box>
        </Card>
        <Modal show={showproduct} onHide={handleCloseproduct} size="lg" style={{ marginLeft: '2%' }}>
          <Modal.Header closeButton>
            <Modal.Title className="title-heading">View Details</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ borderBottom: 'var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)' }}
          >
            <Stack direction="column" alignItems="center" spacing={2}>
              {' '}
              {/* Center items vertically */}
              <Avatar
                alt={currentUser.name}
                src={currentUser.photoUrl}
                style={{ width: '120px', height: '120px', margin: 'auto' }}
              />{' '}
              {/* Increase avatar size */}
              <Stack style={{ width: '100%', textAlign: 'center' }}>
                {' '}
                {/* Center the role text */}
                {/* <b>Role:</b> {currentUser.roles.name} */}
              </Stack>
            </Stack>
            <Stack className="bolderHead row mt-3" direction="row" alignItems="center" justifyContent="space-between">
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Full Name:</b> {currentUser.fullName}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Email:</b> {currentUser.email}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Phone Number:</b> {currentUser.phoneNumber}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>User Name:</b> {currentUser.userName}
                </Stack>
              </div>
              <div className="col-lg-6 mb-3">
                <Stack>
                  <b>Created On:</b> {currentUser.createdOn}
                </Stack>
              </div>
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outlined" color="error" onClick={handleCloseproduct}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleCloseproduct}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}
