import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { Box, Card, Typography, Stack, Switch, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from 'react-bootstrap/Modal';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { fCurrency } from '../../../utils/formatNumber';
import authHeader from "../../../services/auth-header";
// components
import Label from '../../../components/label';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, productImages, price, colors, title, status, priceSale, createdAt, userResponse, description, updatedAt, featured,
    featuredExpDate, likeCount, sold, contactName, contactPhone, contactPhotoUrl, productAddresses, id, blocked

  } = product;
  const cover = productImages[0].imageUrl
  const [checked, setChecked] = React.useState(false);

  console.log(product);
  const {
    // ... (other properties remain unchanged)
    blocked: initialStatus, // Rename status to initialStatus to avoid conflict
  } = product;

  const [statusChange, setStatus] = useState(initialStatus); // Initialize status with initialStatus


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showproduct, setShowproduct] = useState(false);
  const handleCloseproduct = () => setShowproduct(false);
  const handleShowproduct = () => setShowproduct(true);

  const handleBlock = () => {
    fetch(`https://api.caribbargains.com/admin/isblock/status?isBlock=true&productId=${id}&remark=Bad`, {
        method: 'POST', // or 'POST' if your API uses POST for updates
        headers: authHeader(), // Merge the authHeader into the headers
    })
    .then(response => {
        // Handle response if needed
        console.log("Status Updated");
        setStatus(true);
        // Reload the product list or update the product's status locally
    })
    .catch(error => {
        console.error('Error blocking product:', error);
    });
};

const handleUnblock = () => {
    fetch(`https://api.caribbargains.com/admin/isblock/status?isBlock=false&productId=${id}&remark=Bad`, {
        method: 'POST', // or 'POST' if your API uses POST for updates
        headers:authHeader(), // Merge the authHeader into the headers
    })
    .then(response => {
        // Handle response if needed
        console.log("Status Changes");
        setStatus(false);
        // Reload the product list or update the product's status locally
    })
    .catch(error => {
        console.error('Error unblocking product:', error);
    });
};


  return (
    <Card variant="outlined">
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Label
          variant="filled"
          color={status === true ? 'info' : 'error'}
          sx={{
            zIndex: 9,
            top: 0,
            right: 0,
            position: 'absolute',
            textTransform: 'uppercase',
            width: "40px",
            height: "40px",
            borderRadius: "0% 20% 0% 20%"
          }}
        >
          {status === true ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </Label>

        <Label
          variant="filled"
          color={'primary'}
          sx={{
            zIndex: 9,
            left: 0,
            top: 0,
            position: 'absolute',
            textTransform: 'uppercase',
            width: "85px",
            height: "25px",
            borderRadius: "0px 0px 10px 0px"
          }}
        >
          User ID: {userResponse.id}
        </Label>

        <Label
          variant="filled"
          color={'primary'}
          sx={{
            zIndex: 9,
            left: 0,
            bottom: 0,
            position: 'absolute',
            textTransform: 'uppercase',
            borderRadius: "0px 10px 0px 0px"
          }}
        >
          {dayjs(createdAt).format("YY/MM/DD")}
        </Label>
        <Link color="inherit" underline="hover" state={{ item: product }} >
          <StyledProductImg alt={name} src={cover} onClick={handleShowproduct} />
        </Link>

      </Box>

      <Stack spacing={2} sx={{ p: 1 }}>
        <Link color="inherit" underline="hover" style={{ textDecoration: "none" }} onClick={handleShow}>
          <Typography variant="subtitle1" noWrap style={{ color: "#000", }}>
            {title}
          </Typography>
        </Link>


        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}

          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
          <Stack>
                {statusChange === false ? (
                    <Button variant="outlined" color="error" onClick={handleBlock}>
                        Block
                    </Button>
                ) : (
                    <Button variant="outlined" onClick={handleUnblock}>
                        Unblock
                    </Button>
                )}
            </Stack>
        </Stack>

      </Stack>
      <Modal show={showproduct} onHide={handleCloseproduct} size="lg" style={{marginLeft:"2%"}}>
        <Modal.Header closeButton>
          <Modal.Title>{id}. {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ borderBottom: "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)" }}>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between">
            <Stack style={{ width: "33.33%" }}><b>User Name:</b> {userResponse.name}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Email:</b> {userResponse.email}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Mobile:</b> {userResponse.mobile}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between" marginTop={1}>

            <Stack style={{ width: "33.33%" }}><b>Price:</b> {price}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Created at:</b> {dayjs(createdAt).format("YY/MM/DD")}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Featured:</b> {featured === true ? "Yes" : "No"}</Stack>
          </Stack>

          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between" marginTop={1}>

            <Stack style={{ width: "33.33%" }}><b>Featured Expire Date:</b> {dayjs(featuredExpDate).format("YY/MM/DD")}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Status:</b> {status === true ? "Published" : "Unpublished"}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Blocked:</b> {status === true ? "Yes" : "No"}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="start" marginTop={1}>
            {/* <Stack style={{ width: "33.33%" }}><b>View Count:</b> 5</Stack> */}
            <Stack style={{ width: "33.33%" }}><b>Like Count:</b> {likeCount}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Sold:</b> {sold === true ? "Yes" : "No"}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="start" marginTop={1}>
            <Stack style={{ width: "33.33%" }}><b>Contact Person</b> {contactName}</Stack>
            <Stack style={{ width: "33.33%" }}><b>&nbsp;</b> {contactPhone}</Stack>
            <Stack style={{ width: "33.33%" }}><b>&nbsp;</b> {userResponse.email}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="start" marginTop={1}>
            <Stack><b>Contact Address:</b> {productAddresses.state},{productAddresses.city},{productAddresses.country}</Stack>
          </Stack>
        </Modal.Body>
        <Modal.Body style={{ borderBottom: "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)" }}>
          <b>Description</b><br />
          {description}</Modal.Body>
        <Modal.Body >
          <ImageList sx={{ width: "100%", height: 250 }} cols={4} rowHeight={164}>
            {productImages.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.imageUrl}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item.imageUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.id}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseproduct}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseproduct}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Card>
  );
}
