import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Grid, Card, Stack, Container, Typography, TextField, Box, Button, CircularProgress } from '@mui/material';
import './customStyles.css';
import AppSettings from '../services/AppSettings';

export default function CompanySettings() {
  const initialUserService = {
    id: '',
    name: '',
    address: '',
    phoneNo: '',
    email: '',
    // skypeId: '',
    reg_Year: '',
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUser = (id) => {
    setCustomLoader(true);
    AppSettings.getCompany(id)
      .then((response) => {
        console.log('User ID:', id);
        setCurrentUser(response.data);
        setCustomLoader(false);
        console.log(response.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    const userId = '8643bd33-262f-4892-acd9-09959683d08f';
    getUser(userId);
  }, []);

  const isValidEmail = (email) => {
    // Email validation regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Phone number validation regular expression (adjust as needed)
    const phonePattern = /^\+?\d{10}$|^\+?\d{15}$|^\+?\d+\s?\d{10}$|^\+?\d+\s?\d{15}$/;
    return phonePattern.test(phoneNumber);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true);
    // Check if any of the fields in currentUser are empty
    const isEmptyField = Object.values(currentUser).some((value) => value === '');
    if (isEmptyField) {
      // Display a toast message indicating that all fields need to be filled
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Don't proceed with the update if fields are empty
    }
    const isEmailValid = isValidEmail(currentUser.email);
    const isPhoneNumberValid = isValidPhoneNumber(currentUser.phoneNo);

    // Display error messages for invalid fields
    if (!isEmailValid) {
      toast.error('Invalid email format', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!isPhoneNumberValid) {
      toast.error('Invalid phone number', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    AppSettings.updateCompany(currentUser)
      .then((response) => {
        console.log('Update successful:', response.data); // Log the successful response
        // setCurrentUser({ ...currentUser });
        toast.success('Success : Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);

        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Company Setting | ASSR </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Company Setting
          </Typography>
        </Stack>
        <Card>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2} my={2} px={3}>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Id"
                  id="id"
                  name="id"
                  value={currentUser.id}
                  onChange={handleInputChange}
                  hidden
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  id="name"
                  name="name"
                  value={currentUser.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Address"
                  id="address"
                  name="address"
                  value={currentUser.address}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Phone No"
                  id="phoneNo"
                  name="phoneNo"
                  value={currentUser.phoneNo}
                  onChange={handleInputChange}
                  InputProps={{
                    inputProps: {
                      maxLength: 15, // Set the maximum length to 15
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Support Email"
                  id="email"
                  name="email"
                  value={currentUser.email}
                  onChange={handleInputChange}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Skype Id"
                  id="skypeId"
                  name="skypeId"
                  value={currentUser.skypeId}
                  onChange={handleInputChange}
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Registration Year"
                  id="reg_Year"
                  name="reg_Year"
                  value={currentUser.reg_Year}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid spacing={2} my={2} px={3}>
                <Button variant="contained" onClick={updateUser} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  );
}
