import axios from 'axios';

export default axios.create({
  baseURL: 'https://assr.digitalnoticeboard.biz/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

//  Local api access
// export default axios.create({
//   baseURL: "http://localhost:8080/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

//  Server api access
// export default axios.create({
//   baseURL: "https://api.caribbargains.com",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
