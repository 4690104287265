import http from '../http-common';
import authHeader from './auth-header';


const getall = () => http.get('admin/user', {header:authHeader()});
const gethastag = () => {
    console.log('http', http);
    return http.get(`Post/GetAllHashtagDetails`, {header:authHeader()});
};


const hastagService = {
    gethastag,
    getall,
}

export default hastagService;