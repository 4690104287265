import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { ResetPasswordForm } from '../sections/auth/login';



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundImage: `url(/assets/images/auth3-light.webp)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const mdUp = useResponsive('up', 'md');
  const location = useLocation();

  // Function to get query parameters from the URL
  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  // Extract the 'code' parameter from the query string
  const queryParams = getQueryParams(location.search);
  const code = queryParams.get('code');


  return (
    <>
      <Helmet>
        <title> Reset Password | ASSR </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>   
            <ResetPasswordForm code={code}/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
