import http from '../http-common';
import authHeader from './auth-header';

const getAll = () => http.get('/admin/users', { headers: authHeader() });

const getPublicAll = () => http.get('/users');

const get = (id) => http.get(`/user/${id}`);
// const getUserList = () => http.get('/user');

const getPlans = () => {
  console.log('http', http);
  return http.get(`/GetPlans`, { headers: authHeader() });
};

// --action---
const getaction = (id) => {
  return http.get(`/getaction/${id}`, { headers: authHeader() });
  
};

const create = (data) => {
  console.log(data);
  return http.post('/users', data);
};

// const update = (id, data) => http.post(`/users/edit/${id}`, data);

const remove = (id) => http.post(`/users/delete/${id}`);

// const findNearestLocation = (address, latitude, longitude) => http.get(`/users/search?address=${address}&latitude=${latitude}&longitude=${longitude}`);

// const upload = (file, email, onUploadProgress) => {
//   let formData = new FormData();

//   formData.append("file", file);
//   formData.append("email", email);

//   return http.post("/users/uploadFile", formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//     onUploadProgress,
//   });
// };

// const getPaginationAll = (params) => http.get("/user/all", { params });

const getPaginationWithAddrAndCompany = (params) => http.get('/user/all', { params });

const getGlobalSearch = (params) => http.get('/users/paging', { params });
const getProduct = () => http.get('/product/all');

// ASSR APIS
const changePassword = (data) => http.post('/ChangePassword/ChangePassword', data);

const SubscriptionService = {
  getAll,
  get,
  create,
  remove,
  getPaginationWithAddrAndCompany,
  getPublicAll,
  getGlobalSearch,
  getProduct,
  // ASSR
  changePassword,
  getPlans,
  getaction,
};

export default SubscriptionService;
