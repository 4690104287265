import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import AppSettings from '../services/AppSettings';

export default function DevSettings() {
  const initialUserService = {
    id: '',
    testMode: '',
    developerEmail: 'email',
    webMasterEmail: 'email',
    copytodeveloper: '',
    copytoWebmaster: '',
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUser = (id) => {
    setCustomLoader(true);
    AppSettings.getDeveloper(id)
      .then((response) => {
        console.log('anjani', response);
        setCurrentUser(response.data);
        setCustomLoader(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    const UserGuid = '8643bd33-262f-4892-acd9-09959683d08f';
    getUser(UserGuid);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const isValidEmail = (webmasterEmail) => {
    // Email validation regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(webmasterEmail);
  };

  const isValidEmailDev = (developerEmail) => {
    // Email validation regular expression
    const emailPatternDev = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPatternDev.test(developerEmail);
  };

  const updateUser = () => {
    setLoading(true);
    const isEmailValid = isValidEmail(currentUser.webmasterEmail);
    const isEmailValidDev = isValidEmailDev(currentUser.developerEmail);

    // Display error messages for invalid fields
    if (!isEmailValid) {
      toast.error('Invalid email format', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }
    if (!isEmailValidDev) {
      toast.error('Invalid email format', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    AppSettings.updateDeveloper(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success('Success : Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>Developer Settings | ASSR </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Developer Setting
          </Typography>
        </Stack>
        <Card>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
            {/* <div>
              <TextField
                fullWidth
                label="Id"
                id="id"
                name="id"
                value={currentUser.id}
                onChange={handleInputChange}
                hidden
              />
            </div> */}
            <div>
              <TextField
                fullWidth
                label="Webmaster Email"
                id="webmasterEmail"
                name="webMasterEmail"
                value={currentUser.webMasterEmail}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Send Copy To Web Master"
                  sx={{ marginLeft: 1 }}
                  id="webmasterEmail"
                  name="copytoWebmaster"
                  value={currentUser.copytoWebmaster}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </div>
            <div>
              <TextField
                fullWidth
                label="Developer Email"
                id="developerEmail"
                name="developerEmail"
                value={currentUser.developerEmail}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Test Mode"
                  sx={{ marginLeft: 1 }}
                  id="testMode"
                  name="testMode"
                  value={currentUser.testMode}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Send Copy To Developer"
                  sx={{ marginLeft: 1 }}
                  id="sendCopyDeveloper"
                  name="copytodeveloper"
                  value={currentUser.copytodeveloper}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </div>
            <div style={{ padding: 15, margin: 5 }}>
              <Button variant="contained" onClick={updateUser} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
