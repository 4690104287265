import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner';
import jwtDecode from 'jwt-decode';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Card, Stack, Container, Typography, TextField, Box, Button, CircularProgress } from '@mui/material';
import './customStyles.css';

import UserServices from '../services/UserServices';

export default function ChangePasswordSettings() {
  const token = localStorage.getItem('token');
  const decoded = token ? jwtDecode(token) : null;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const data = {
    userGUId: decoded.userGUId,
    oldPassword,
    newPassword,
    confirmPassword,
  };

  const updatePassword = () => {
    setLoading(true);
    // Check if any of the fields in currentUser are empty
    if (!confirmPassword || !newPassword || !oldPassword) {
      // Display a toast message indicating that all fields need to be filled
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Don't proceed with the update if fields are empty
    }

    if (confirmPassword !== newPassword) {
      toast.error("New Password and Confirm Password doesn't match", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Don't proceed with the update if fields are empty
    }

    UserServices.changePassword(data)
      .then((response) => {
        if (!response.data.status) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.success('Success : Updated Successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e.response.data.errors);
        const errorResponse = e.response.data.errors;
        Object.entries(errorResponse).forEach(([key, value]) => {
          value.forEach((errorMessage) => {
            toast.error(`${errorMessage}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Change Password | ASSR </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Change Password
          </Typography>
        </Stack>
        <Card>


          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="row my-3">
              <div className="col-lg-7">
                <TextField
                  fullWidth
                  label="Old Password"
                  id="oldPassword"
                  name="oldPassword"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="col-lg-7">
                <TextField
                  fullWidth
                  label="New Password"
                  id="newPassword"
                  name="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="col-lg-7">
                <TextField
                  fullWidth
                  label="Confirm Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <div style={{ padding: 2, margin: 15 }}>
              <Button variant="contained" onClick={updatePassword} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
