import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  styled,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './customStyles.css';
import CategoriesServices from '../services/CategoriesServices';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState('Please Select Category');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedCategoryFee, setSelectedCategoryFee] = useState('');
  const getAllCategories = () => {
    CategoriesServices.getAllCategories()
      .then((response) => {
        setCategories(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log('Error', error);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  // Flatten the categories and subcategories into a flat list of items
  const flattenCategories = (categories) => {
    const flattenedItems = [];
    categories.forEach((category) => {
      // Add the category itself as an item
      flattenedItems.push({
        id: `category-${category.id}`,
        content: category.name,
        categoryId: category.id,
        categoryFee: category.freeAdPrice,
      });
    });
    return flattenedItems;
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    // Flatten the categories and setItems
    const flattenedItems = flattenCategories(categories);
    setItems(flattenedItems);
  }, [categories]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };

  console.log('items', items);

  return (
    <>
      <Helmet>
        <title> Categories | Carib Bargains </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 2, width: '60ch' },
            flexGrow: 1,
          }}
          noValidate
          autoComplete="off"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Item>
                  <Button variant="contained" fullWidth disableElevation>
                    All Categories
                  </Button>
                  <Box>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <List {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((item, index) => (
                              <>
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                  {(provided) => (
                                    <ListItemButton
                                      variant="outlined"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onClick={() => {
                                        const categoryId = item.id.split('-')[1];
                                        const selectedCategory = categories.find(
                                          (category) => category.id === parseInt(categoryId, 10)
                                        );
                                        if (selectedCategory && selectedCategory.subCategory) {
                                          console.log(selectedCategory.subCategory);
                                          setSelectedSubcategories(selectedCategory.subCategory);
                                          setSelectedCategoryName(item.content);
                                          setSelectedCategoryId(item.categoryId);
                                          setSelectedCategoryFee(item.categoryFee);
                                        } else {
                                          setSelectedSubcategories([]); // No subcategories found, set an empty array
                                          setSelectedCategoryName('');
                                          setSelectedCategoryId('');
                                          setSelectedCategoryFee('');
                                        }
                                      }}
                                    >
                                      <ListItemIcon>
                                        <DragHandleIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={item.content} />
                                    </ListItemButton>
                                  )}
                                </Draggable>
                                <Divider />
                              </>
                            ))}
                            {provided.placeholder}
                          </List>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Item>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography sx={{ display: 'flex', alignItems: 'flex-start' }}>{selectedCategoryName}</Typography>
                    <Button
                      // component={RouterLink}
                      // to="/dashboard/addcountries"
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      Add Sub Category
                    </Button>
                  </Stack>

                  <Divider />
                  {/* {console.log(categories.subCategory)} */}
                  {selectedSubcategories?.map((item, index) => (
                    <div key={index}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: { sm: '1fr 1fr' },
                              gap: 1,
                            }}
                          >
                            <TextField label="Title" value={item.name} size="small" sx={{ width: '30ch !important' }} />
                            <TextField label="Slug" value={item.slug} size="small" sx={{ width: '30ch !important' }} />
                          </Box>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: { sm: '1fr 1fr' },
                              gap: 0.5,
                            }}
                          >
                            <TextField
                              value={selectedCategoryFee}
                              label="Ads Fee Price"
                              size="small"
                              sx={{ width: '30ch !important' }}
                              disabled
                            />
                            <TextField
                              label="Parent Category Id"
                              value={selectedCategoryId}
                              size="small"
                              sx={{ width: '30ch !important' }}
                              disabled
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: { sm: '1fr 1fr' },
                              gap: 1,
                            }}
                          >
                            {/* <TextField label="Icon Image Url" size="small" sx={{ width: '30ch !important' }} /> */}
                            <FormControl sx={{ m: 2, width: '30ch !important' }} size="small">
                              <InputLabel id="demo-select-small-label">Price Required</InputLabel>
                              <Select
                                value={item.priceRequired}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="Price Required"
                              >
                                <MenuItem value="true">True</MenuItem>
                                <MenuItem value="false">False</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <div style={{ textAlign: 'end', marginRight: '22px' }}>
                            <Button variant="contained" color="success">
                              Update
                            </Button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
}
