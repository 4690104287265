import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Card, Stack, Container, Typography, TextField, Box, Button, CircularProgress } from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import './customStyles.css';
import AppSettings from '../services/AppSettings';

export default function NotificationSettings() {
  const initialUserService = {
    id: '',
    fcmId: '',
  };

  const [currentUser, setCurrentUser] = useState(initialUserService);
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  const getUser = () => {
    setCustomLoader(true);
    AppSettings.getNotification()
      .then((response) => {
        setCurrentUser(response.data.data);
        setCustomLoader(false);
        console.log(response.data.data);
      })
      .catch((e) => {
        setCustomLoader(false);
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // useEffect(() => {
  //   getUser();
  // }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const updateUser = () => {
    setLoading(true);
    const isEmptyField = Object.values(currentUser).some((value) => value === '');

    if (isEmptyField) {
      // Display a toast message indicating that all fields need to be filled
      toast.error('Please fill in all fields', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Don't proceed with the update if fields are empty
    }
    AppSettings.updateNotification(currentUser)
      .then((response) => {
        setCurrentUser({ ...currentUser });
        toast.success('Success : Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  const initialDeviceSate = {
    deviceToken: '',
  };

  const [fcmId, setFcm] = useState(initialDeviceSate);

  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setFcm({ ...fcmId, [name]: value });
  };

  const noticationSend = () => {
    const isEmptyFields = Object.values(fcmId).some((value) => value === '');

    if (isEmptyFields) {
      toast.error("Error: Device ID field can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if fcmId is blank
    }
    setLoadingSend(true);
    AppSettings.sendTestNotification(fcmId)
      .then((response) => {
        // console.log(JSON.stringify(fcmId, null, 2));
        toast.success('Success : Send Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(response.data.data);
        setLoadingSend(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingSend(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Notification Settings | Carib Bargains </title>
      </Helmet>

      <Container>
        {customLoader && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999999999999,
            }}
          >
            <CirclesWithBar
              height="100"
              width="100"
              color="#4fa94d"
              visible={customLoader}
              ariaLabel="circles-with-bar-loading"
            />
          </div>
        )}
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Notification Setting
          </Typography>
        </Stack>
        <Card>
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
             <div>
            <TextField fullWidth label="Id" id="id" name='id' value={currentUser.id}
                onChange={handleInputChange} hidden/>
            </div>
            <div>
            <TextField fullWidth label="FEM Id" id="fcmId" name='fcmId' value={currentUser.fcmId}
                onChange={handleInputChange} />
            </div>
            <div style={{padding:2, margin:5}}>
              <Button variant="contained" onClick={updateUser} disabled={loading}>{loading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </div>
        </Box>
        </Card> */}
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} >
          <Typography variant="h4" gutterBottom>
            FCM Settings
          </Typography>
        </Stack>
        <Card style={{ padding: 15 }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                fullWidth
                label="Sender Id / API Key"
                id="deviceToken"
                name="deviceToken"
                value={fcmId.deviceToken}
                onChange={handleInputChanges}
              />
            </div>

            <div style={{ padding: 15, margin: 5 }}>
              <Button variant="contained" onClick={noticationSend} disabled={loadingSend}>
                {loadingSend ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </div>
          </Box>
        </Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={2}>
          <Typography variant="h4" gutterBottom>
            Send Test Notification
          </Typography>
        </Stack>
        <Card style={{ padding: 15 }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '60ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                fullWidth
                label="Device Id"
                id="deviceToken"
                name="deviceToken"
                value={fcmId.deviceToken}
                onChange={handleInputChanges}
              />
            </div>

            <div style={{ padding: 15, margin: 5 }}>
              <Button variant="contained" onClick={noticationSend} disabled={loadingSend}>
                {loadingSend ? <CircularProgress size={24} /> : 'Send'}
              </Button>
            </div>
          </Box>
        </Card>
      </Container>
    </>
  );
}
