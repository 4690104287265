import { Helmet } from 'react-helmet-async';
import React, { forwardRef, useEffect, useState } from 'react';

// @mui
import {
    Stack,
    Button,
    Container,
    Typography,
    Box,
    styled,
    Pagination,
    Switch,
    //   Modal
} from '@mui/material';
import { CirclesWithBar } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import FormControlLabel from '@mui/material/FormControlLabel';
import './customStyles.css';

import { GridPagination, GridFooterContainer, } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import UserServices from '../services/UserServices';




const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

// const Fade = forwardRef((props, ref) => {
//     const {
//         children,
//         in: open,
//         onClick,
//         onEnter,
//         onExited,
//         ownerState,
//         ...other
//     } = props;
//     const style = useSpring({
//         from: { opacity: 0 },
//         to: { opacity: open ? 1 : 0 },
//         onStart: () => {
//             if (open && onEnter) {
//                 onEnter(null, true);
//             }
//         },
//         onRest: () => {
//             if (!open && onExited) {
//                 onExited(null, true);
//             }
//         },
//     });

//     return (
//         <animated.div ref={ref} style={style} {...other}>
//             {React.cloneElement(children, { onClick })}
//         </animated.div>
//     );
// });


// Fade.propTypes = {
//     children: PropTypes.element.isRequired,
//     in: PropTypes.bool,
//     onClick: PropTypes.any,
//     onEnter: PropTypes.func,
//     onExited: PropTypes.func,
//     ownerState: PropTypes.any,
// };


export default function ProductsPage() {
    const [data, setdata] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [show, setShow] = useState(false);

    const handleCloses = () => {

        setShow(false)
    };
    const handleShow = () => {

        setShow(true);
    }

    const [customLoader, setCustomLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState([]);


    const getRequestParams = (page, pageSize) => {
        const params = {};
        if (page) {
            console.log("if page", page, params)
            params.page = page - 1;
        }
        if (pageSize) {
            console.log("if pageSize", pageSize, params)
            params.size = pageSize;
        }
        return params;
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const handlePageSizeChange = (event) => {
        setPageSize(event);
        setPage(1);
    };

    const retrieveTutorials = () => {
        setLoading(true);
        setCustomLoader(true);
        const param = getRequestParams(
            page,
            pageSize
        );

        UserServices.getProduct(param)
            .then((response) => {
                const { user, totalPages, totalItems, data } = response.data;
                if (user === 0 || user === undefined) {
                    setdata(0);
                } else {
                    setdata(user);
                }
                setLoading(false);
                setProduct(data)
                setCustomLoader(false);
                console.log("response.data", product);
            })
            .catch((e) => {
                setLoading(false);
                setCustomLoader(false);
                console.log(e);
            });
    };

    // eslint-disable-next-line
    useEffect(retrieveTutorials, [page, pageSize]);


    const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };


    return (
        <>
            <Helmet>
                <title> Products | Carib Bargains </title>
            </Helmet>

            <Container>
                {customLoader && (
                    <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999999999999,
                    }}
                    >
                    <CirclesWithBar
                        height="100"
                        width="100"
                        color="#4fa94d"
                        visible={customLoader}
                        ariaLabel="circles-with-bar-loading"
                    />
                    </div>
                )}
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Products
                </Typography>

                <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
                    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                        <ProductFilterSidebar
                            openFilter={openFilter}
                            onOpenFilter={handleOpenFilter}
                            onCloseFilter={handleCloseFilter}
                        />
                        <ProductSort />
                    </Stack>
                </Stack>

                <ProductList products={product} />
                {/* <ProductCartWidget /> */}
            </Container>
        </>
    );
}


