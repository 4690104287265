import http from '../http-common';
import authHeader from './auth-header';

const getall = () => http.get('admin/user', { header: authHeader() });

const grouplist = () => {
  console.log('http', http);
  return http.get(`GroupList`, { header: authHeader() });
};

const UsergroupService = {
  getall,
  grouplist,
};

export default UsergroupService;
